import Vue from 'vue';
import * as rules from 'vee-validate/dist/rules';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from 'vee-validate';

import ipUtils from '@/xvisor/utilities/ipUtils';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend('ipValid', (value) => (ipUtils.isValidIp(value) ? true : 'IP format is invalid'));

extend('portListValid', (value) => (
  value
    .replace(/\s+/g, '')
    .split(',')
    .every((item) => item > 0 && item < 65535)
    ? true
    : 'Ports must be a list of comma separated numbers between 0 and 65535'
));

extend('portValid', (value) => (
  value > 0 && value < 65535 ? true : 'Port must be a number between 0 and 65535'
));

extend('prefixListValid', (value) => (
  value
    .replace(/\s+/g, '')
    .split(',')
    .every((item) => ipUtils.isValidIpPrefix(item) || ipUtils.isValidIp(item))
    ? true
    : 'Prefix list format is invalid'
));

extend('validPassword', (value) => {
  const validPassword = new RegExp(
    "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]).{10,}$",
  ).test(value);

  if (!validPassword) {
    return 'Your password must contain at least ten characters including: at least one uppercase letter (A-Z); '
      + 'at least one lowercase letter (a-z); at least one number (0-9); '
      + 'and at least one special character (e.g. ! @ # $ % ^ & *).';
  }
  return true;
});

const urlRegex = new RegExp(
  '^(?:(?:http|https|ftp)://)?(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])'
    + '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|'
    + '(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)'
    + '*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
);

extend('url', (value) => {
  const validUrl = urlRegex.test(value);
  if (!validUrl) {
    return 'This field must be a valid URL';
  }
  return true;
});

extend('urlOrIp', (value) => urlRegex.test(value) || ipUtils.isValidIp(value));

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
