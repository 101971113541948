<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">xVisor</h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid src="@/assets/images/pages/login-v2.svg" alt="Login V2" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-0 px-lg-0 mx-auto">
          <div class="mx-n2" @keydown.enter="submitLogin">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">xVisor · Login</b-card-title>
            <p class="mb-1">Please login to your account.</p>
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form-group label="Email *" label-for="email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input
                    type="email"
                    id="email"
                    v-model="login.email"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    :autofocus="true"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Password *" label-for="password">
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="password"
                      v-model="login.password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                    />
                    <b-input-group-append is-text>
                      <b-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
              <b-row class="my-1">
                <b-col>
                  <b-form-checkbox v-model="login.rememberMe"><small>Remember Me</small></b-form-checkbox>
                </b-col>
                <b-col align="right">
                  <a href="/forgotpasswd" class="text-light"><small>Forgot Password?</small></a>
                </b-col>
              </b-row>
              <b-button @click="submitLogin" variant="primary" block :disabled="invalid">Login</b-button>
            </validation-observer>
          </div>
        </b-col>
      </b-col>
    </b-row>
    <b-modal id="passwordResetModal" ok-only centered title="Password Reset">
      You need to reset your password.
      We have sent you an email with instructions
      on how to reset your password.
    </b-modal>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import generateHash from '@/xvisor/utilities/generateHash';

export default {
  mixins: [togglePasswordVisibility],
  data() {
    return {
      login: {
        email: '',
        password: '',
        rememberMe: false,
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'eye' : 'eye-slash';
    },
  },
  methods: {
    submitLogin() {
      this.$http
        .post('/login', {
          ...this.login,
          password: generateHash(this.login.password),
        })
        .then(() => { window.location.replace('/'); })
        .catch((error) => {
          const errorStatus = error.response.status;
          if (errorStatus === 409) {
            this.$bvModal.show('passwordResetModal');
          } else {
            let msg = 'Login Failed';
            if (errorStatus === 400) msg = 'Invalid data is submitted.';
            if (errorStatus === 401) msg = 'Invalid email or password.';
            if (errorStatus === 500) msg = 'The system experienced an error. Please try again later.';
            this.$bvToast.toast(`${msg}`, { variant: 'danger' });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
