<template>
  <b-card title="Settings">
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Email Notifications
      </h6>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox v-model="settings.alertEmails" switch inline>
          <span>Email me when there is an alert</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox v-model="settings.reportEmails" switch inline>
          <span>Email me when there is a report</span>
        </b-form-checkbox>
      </b-col>

      <b-col cols="12">
        <b-button
          variant="primary"
          class="mr-1 mt-1"
          @click="saveSettings"
        >
          Save changes
        </b-button>
        <b-button
          type="reset"
          class="mt-1"
          variant="outline-primary"
          @click.prevent="httpGet"
        >
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import colorPalette from '@/xvisor/constants/colorPalette';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    updateUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        alertEmails: false,
        reportEmails: false,
      },
      colorPalette,
    };
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.settings = response.data; })
        // Do nothing on error or if settings not found.
        .catch(() => {});
    },
    saveSettings() {
      this.$http
        .post(this.updateUrl, this.settings)
        .then(() => {
          this.$bvToast.toast('Successfully updated user settings', {
            title: 'Notice',
            variant: 'success',
          });
        })
        .catch(() => {
          this.$bvToast.toast('Update unsuccessful', {
            title: 'Notice',
            variant: 'danger',
          });
        });
    },
  },
};
</script>
