/** Take the ceiling of the date based on the given minutes granularity.
 *
 * @param date The date for which to get the ceiling.
 * @param minuteGranularity The granularity in minutes.
 * @return The ceiling date.
 */
export default function dateCeil(date, minuteGranularity = 5) {
  const msGranularity = minuteGranularity * 60 * 1000;
  return new Date(Math.ceil(date.getTime() / msGranularity) * msGranularity);
}
