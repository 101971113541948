import Vue from 'vue';

import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/gauge';
import 'echarts/lib/chart/graph';
import 'echarts/lib/chart/heatmap';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/sankey';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/chart/treemap';
import 'echarts/lib/component/angleAxis';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markArea';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/polar';
import 'echarts/lib/component/radiusAxis';
import 'echarts/lib/component/singleAxis';
import 'echarts/lib/component/title';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/visualMap';

Vue.component('e-chart', ECharts);
