<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">xVisor</h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img src="@/assets/images/pages/reset-password-v2.svg" fluid alt="Register V2" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto" @keydown.enter="reset">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">Reset Password 🔒</b-card-title>
          <p class="mb-1">Please enter your new password.</p>
          <validation-observer ref="resetForm" v-slot="{ invalid }">
            <b-form-group label="New Password *" label-for="reset-password-new">
              <validation-provider #default="{ errors }" name="Password" vid="Password" rules="required|validPassword">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="newPasswordFieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    :autofocus="true"
                  />
                  <b-input-group-append is-text>
                    <b-icon class="cursor-pointer" :icon="newPasswordToggleIcon" @click="toggleNewPasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="reset-password-confirm" label="Confirm Password *">
              <validation-provider #default="{ errors }" name="Confirm Password" rules="required|confirmed:Password">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="confirmPassword"
                    :type="confirmPasswordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                  />
                  <b-input-group-append is-text>
                    <b-icon
                      class="cursor-pointer"
                      :icon="confirmPasswordToggleIcon"
                      @click="toggleConfirmPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-button class="w-100 mb-1" variant="primary" @click="reset" :disabled="invalid">Reset Password</b-button>
          </validation-observer>
          <b-button class="w-100" variant="outline-primary" @click="redirectLogin">Back To Login</b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import generateHash from '@/xvisor/utilities/generateHash';

export default {
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      confirmPassword: '',
      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
    };
  },
  computed: {
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'eye' : 'eye-slash';
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'eye' : 'eye-slash';
    },
  },
  methods: {
    toggleNewPasswordVisibility() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password';
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    },
    reset() {
      this.$refs.resetForm.validate().then((result) => {
        if (result) {
          this.$http
            .post(`/resetpasswd/${this.$route.params.resetPasswdId}`, {
              newPasswd: generateHash(this.password),
              newPasswdConfirm: generateHash(this.confirmPassword),
            })
            .then(() => {
              this.$bvToast.toast('You have successfully reset your password!', { variant: 'success' });
            })
            .catch(() => {
              this.$bvToast.toast('Failed to reset password!', { variant: 'danger' });
            });
        }
      });
    },
    redirectLogin() {
      window.location.replace('/login');
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
