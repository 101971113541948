<template>
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page Not Found
        </h2>
        <p class="mb-2">
          The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to Home
        </b-button>

        <b-img
          fluid
          :src="downImg"
          alt="Error page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

export default {
  data() {
    return {
      downImg: require('@/assets/images/pages/error-dark.svg'),
    };
  },
};
</script>

<style lang="scss">
@import '../../../@core/scss/vue/pages/page-misc.scss';
</style>
