<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">xVisor</h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid src="@/assets/images/pages/forgot-password-v2.svg" alt="Forgot Password" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto" @keydown.enter="submitEmail">
          <b-card-title class="mb-1">Forgot Password? 🔒</b-card-title>
          <p class="mb-1">
            Enter your email and we'll send you instructions to reset your password.
          </p>
          <validation-observer ref="emailForm" v-slot="{ invalid }">
            <b-form-group label="Email *" label-for="forgot-password-email">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input
                  id="forgot-password-email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                  type="email"
                  :autofocus="true"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-button class="w-100 mb-1" variant="primary" @click="submitEmail" :disabled="invalid">
              Send reset link
            </b-button>
          </validation-observer>
          <b-button variant="outline-primary" class="w-100" @click="redirectLogin">Back to Login</b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      sideImg: '@/assets/images/pages/forgot-password-v2.svg',
    };
  },
  methods: {
    submitEmail() {
      this.$refs.emailForm.validate().then((result) => {
        if (result) {
          this.$http
            .post('/forgotpasswd', { email: this.email })
            .then(() => {
              this.$bvToast.toast(`An Email was sent to ${this.email}`, { variant: 'success' });
            })
            .catch(() => {
              this.$bvToast.toast('User with this email address not found!', { variant: 'danger' });
            });
        }
      });
    },
    redirectLogin() {
      window.location.replace('/login');
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
