<template>
  <div>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <h2>Account Settings</h2>
      </b-col>
    </b-row>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      lazy
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >

      <!-- General Tab -->
      <b-tab active>
        <template #title>
          <b-icon icon="person" class="mr-1" />
          <span class="font-weight-bold">General</span>
        </template>
          <AccountInfo
            :url="profileUrl"
            :updateUrl="profileUpdateUrl"
            class="mx-n2"
          ></AccountInfo>
      </b-tab>

      <!-- Change Password Tab -->
      <b-tab>
        <template #title>
          <b-icon icon="lock" class="mr-1" />
          <span class="font-weight-bold">Change Password</span>
        </template>
          <PasswordManager
            :changePasswdUrl="changePasswdUrl"
            class="mx-n2"
          ></PasswordManager>
      </b-tab>

      <!-- Notifications Tab -->
      <b-tab>
        <template #title>
          <b-icon icon="bell" class="mr-1" />
          <span class="font-weight-bold">Notifications</span>
        </template>
          <NotificationSettings
            :url="notifSettingsUrl"
            :updateUrl="notifSettingsUpdateUrl"
            class="mx-n2"
          ></NotificationSettings>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AccountInfo from '@/xvisor/components/profile/AccountInfo.vue';
import NotificationSettings from '@/xvisor/components/profile/NotificationSettings.vue';
import PasswordManager from '@/xvisor/components/profile/PasswordManager.vue';

export default {
  components: {
    AccountInfo,
    NotificationSettings,
    PasswordManager,
  },
  data() {
    return {
      profileUrl: '/profile.json',
      profileUpdateUrl: '/profile',
      notifSettingsUrl: '/settings.json',
      notifSettingsUpdateUrl: '/settings',
      changePasswdUrl: '/changepasswd',
    };
  },
};
</script>
