import momentTimeFormat from '../constants/momentTimeFormat';

export default {
  /** Determine the format of the time in the moment library based on how the time series are spanned.
   *
   * @param timeSet The times in UTC format.
   * @param date The current Date.
   * @param justHour The flag that indicates if the graph should only show the hour in the time.
   * @returns A time format.
   */
  momentFormat(timeSet, date, justHour = false) {
    const today = date.toISOString().slice(0, 10);
    const daysSet = new Set(Array.from(timeSet).map((time) => time.slice(0, 10)));
    if (daysSet.size === 1 && !daysSet.has(today)) {
      if (justHour) return momentTimeFormat.dateTimeHour;
      return momentTimeFormat.dateTime;
    }
    if (daysSet.size <= 1) {
      if (justHour) return momentTimeFormat.timeHour;
      return momentTimeFormat.time;
    }
    if (daysSet.size === timeSet.size) return momentTimeFormat.date;
    if (justHour) return momentTimeFormat.dateTimeHour;
    return momentTimeFormat.dateTime;
  },
};
