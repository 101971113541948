<template>
  <b-card>
    <validation-observer ref="changePasswordForm">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Current Password *"
              label-for="current-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Current Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="passwdForm.currentPasswd"
                    name="current-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Current Password"
                  />
                  <b-input-group-append is-text>
                    <b-icon
                      :icon="oldPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="toggleOldPassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger" v-if="errors[0]">Current password is required.</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="reset-password-new"
              label="New Password *"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|validPassword"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="passwdForm.newPasswd"
                    :type="newPasswordFieldType"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <b-icon
                      :icon="newPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="toggleNewPassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="!passwdForm.newPasswd && errors[0]">Password is required.</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password *"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="passwdForm.newPasswdConfirm"
                    :type="confirmPasswordFieldType"
                    class="form-control-merge"
                    name="reset-password-confirm"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <b-icon
                      :icon="confirmPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="confirmPasswordToggle"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="!passwdForm.newPasswdConfirm && errors[0]">Confirm password is required.</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-1 mr-1"
              @click="changePasswd"
            >
              Save changes
            </b-button>
            <b-button
              type="reset"
              variant="outline-primary"
              class="mt-1"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import generateHash from '@/xvisor/utilities/generateHash';

export default {
  props: {
    changePasswdUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      passwdForm: {
        currentPasswd: '',
        newPasswd: '',
        newPasswdConfirm: '',
      },
      passwordFieldTypeOld: 'password',
      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
    };
  },
  computed: {
    oldPasswordToggleIcon() {
      return this.passwordFieldTypeOld === 'password' ? 'eye' : 'eye-slash';
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'eye' : 'eye-slash';
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'eye' : 'eye-slash';
    },
  },
  methods: {
    changePasswd() {
      this.$refs.changePasswordForm.validate().then((result) => {
        if (result) {
          this.$http
            .post(this.changePasswdUrl, {
              currentPasswd: generateHash(this.passwdForm.currentPasswd),
              newPasswd: generateHash(this.passwdForm.newPasswd),
              newPasswdConfirm: generateHash(this.passwdForm.newPasswdConfirm),
            })
            .then(() => {
              this.$bvToast.toast('Successfully updated user password', {
                title: 'Notice',
                variant: 'success',
              });
            })
            .catch(() => {
              this.$bvToast.toast('Update unsuccessful', {
                title: 'Notice',
                variant: 'danger',
              });
            });
        }
      });
    },
    toggleOldPassword() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password';
    },
    toggleNewPassword() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password';
    },
    confirmPasswordToggle() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>
