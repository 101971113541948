export default {

  getSeconds(time) {
    return Math.round(time.getTime() / 1000);
  },

  // Calculate the granularity that creates approximately 72 buckets within the given time range.
  granularity72(timeRange) {
    const duration = this.getSeconds(timeRange.end) - this.getSeconds(timeRange.start);
    const durationInHours = Math.floor(duration / 3600);
    if (durationInHours <= 6) return 5;
    if (durationInHours <= 12) return 10;
    if (durationInHours <= 18) return 15;
    if (durationInHours <= 24) return 20;
    if (durationInHours <= 36) return 30;
    if (durationInHours <= 48) return 40;
    if (durationInHours <= 72) return 60;

    // Approximate a fixed number of 72 buckets.
    return Math.ceil(durationInHours * (5 / 6));
  },

  // Calculate the granularity that creates approximately 24 buckets within the given time range.
  granularity24(timeRange) {
    const duration = this.getSeconds(timeRange.end) - this.getSeconds(timeRange.start);
    const durationInHours = Math.floor(duration / 3600);
    if (durationInHours <= 2) return 5;
    if (durationInHours <= 6) return 15;
    if (durationInHours <= 12) return 30;

    // Approximate a fixed number of 72 buckets.
    return Math.ceil(durationInHours * (5 / 2));
  },

  // Calculate the granularity that creates approximately 12 buckets within the given time range.
  granularity12(timeRange) {
    const duration = this.getSeconds(timeRange.end) - this.getSeconds(timeRange.start);
    const durationInHours = Math.floor(duration / 3600);
    if (durationInHours <= 1) return 5;
    if (durationInHours <= 2) return 10;
    if (durationInHours <= 3) return 15;
    if (durationInHours <= 6) return 30;
    if (durationInHours <= 12) return 60;

    // Approximate a fixed number of 12 buckets.
    return Math.ceil(durationInHours * 5.0);
  },

  // Calculate the granularity that creates approximately 6 buckets within the given time range.
  granularity6(timeRange) {
    const duration = this.getSeconds(timeRange.end) - this.getSeconds(timeRange.start);
    const durationInHours = Math.floor(duration / 3600);
    const durationInMinutes = Math.floor(duration / 60);
    if (durationInMinutes <= 30) return 5;
    if (durationInHours <= 1) return 10;
    if (durationInHours <= 2) return 20;
    if (durationInHours <= 3) return 30;
    if (durationInHours <= 6) return 60;
    if (durationInHours <= 12) return 120;

    // Approximate a fixed number of 6 buckets.
    return Math.ceil(durationInHours * 10.0);
  },
};
