import isIP from 'validator/lib/isIP';

const matchByte = '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
const prefixIp = '((?:[0-9])|(?:[1-2][0-9])|(?:3[0-2]))';
const prefixIpRegex = new RegExp(`^${matchByte}\\.${matchByte}\\.${matchByte}\\.${matchByte}/${prefixIp}$`);

export default {
  PREFIX_IP_REGEX: prefixIpRegex,

  ipToNumber(ipAddress) {
    const arrIp = ipAddress.split('.');

    const segment1 = parseInt(arrIp[0], 10);
    const segment2 = parseInt(arrIp[1], 10);
    const segment3 = parseInt(arrIp[2], 10);
    const segment4 = parseInt(arrIp[3], 10);

    const calc = segment4 + (segment3 * 256) + (segment2 * 65536) + (segment1 * 16777216);

    return calc;
  },

  compareIps(ip1, ip2) {
    return this.ipToNumber(ip1) - this.ipToNumber(ip2);
  },

  isValidIp(text) {
    return isIP(text, 4) || isIP(text, 6);
  },

  isValidIpPrefix(text) {
    return prefixIpRegex.test(text);
  },
};
