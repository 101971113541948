<template>
  <div>
    <b-input-group class="input-group-merge" :size=sizeParameters.formSize id="filter">
      <b-input-group-prepend is-text>
        <b-icon icon="search" :font-scale=sizeParameters.iconSize />
      </b-input-group-prepend>
      <slot name="input"></slot>
    </b-input-group>
    <slot v-if="hasErrorMessage" name="errorMessage"></slot>
    <b-popover
      v-if="hasToolTip"
      target="filter"
      triggers="hover"
      placement="bottom"
      class="large-width"
    >
      <div class="popover-content">
        <slot name="tooltip"></slot>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  props: {
    hasToolTip: {
      type: Boolean,
      default: false,
    },
    hasErrorMessage: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
      validator: (value) => [
        'sm',
        'md',
        'lg',
      ].includes(value),
    },
  },
  computed: {
    sizeParameters() {
      if (this.size === 'sm') {
        return { formSize: 'sm', iconSize: '1' };
      }
      if (this.size === 'md') {
        return { formSize: 'md', iconSize: '1' };
      }
      return { formSize: 'lg', iconSize: '1' };
    },
  },
};
</script>

<style scoped>
.popover-content{
  font-size: 10px;
}
</style>
