export default function phoneNumKeydownListener(event) {
  const eventRef = event;
  eventRef.target.value = event.target.value
    .replace(/\D/g, '')
    .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, (txt, areaCode, first, last) => {
      if (last) return `(${areaCode}) ${first}-${last}`;
      if (first) return `(${areaCode}) ${first}`;
      if (areaCode) return `(${areaCode})`;
      return '';
    });
}
