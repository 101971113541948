import Vue from 'vue';
import axios from 'axios';

const axiosIns = axios.create({});

axiosIns.interceptors.response.use((response) => {
  const url = new URL(response.request.responseURL);
  if (url.pathname == '/login') {
    window.location = '/login';
  }
  return response;
}, (error) => Promise.reject(error));

Vue.prototype.$http = axiosIns;

export default axiosIns;
