<template>
  <b-card>
    <validation-observer ref="accountInfoForm">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Name" label-for="account-name">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="person" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="user.name"
                  name="name"
                  placeholder="Name"
                  autofocus
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group label="E-mail" label-for="account-e-mail" >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-icon icon="envelope" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="user.email"
                  name="email"
                  placeholder="Email"
                  readonly
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider rules="min:14" v-slot="{ errors }">
              <b-form-group label="Phone Number" label-for="account-phone-number" >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <b-icon icon="phone" />
                  </b-input-group-prepend>
                  <b-form-input
                    ref="phoneNum"
                    v-model="user.phoneNum"
                    name="phone number"
                    placeholder="(123) 456-7890"
                    @keydown="phoneNumKeydownListener"
                  />
                </b-input-group>
                <span class="text-danger text-sm" v-if="errors[0]">Incorrect number of digits</span>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-2 mr-1"
              @click="saveProfile"
            >
              Save changes
            </b-button>
            <b-button
              variant="outline-primary"
              type="reset"
              class="mt-2"
              @click.prevent="httpGet"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import phoneNumKeydownListener from './phoneNumKeydownListener';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    updateUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: {
        name: '',
        phoneNum: '',
        email: '',
      },
    };
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url)
        .then((response) => { this.user = response.data; });
    },
    saveProfile() {
      this.$refs.accountInfoForm.validate().then((result) => {
        if (result) {
          this.$http
            .post(this.updateUrl, {
              name: this.user.name,
              phoneNum: this.user.phoneNum,
            })
            .then(() => {
              this.httpGet();
              this.$bvToast.toast('Successfully updated user profile', {
                title: 'Notice',
                variant: 'success',
              });
            })
            .catch(() => {
              this.$bvToast.toast('Update unsuccessful', {
                title: 'Notice',
                variant: 'danger',
              });
            });
        }
      });
    },
    phoneNumKeydownListener,
  },
};
</script>
