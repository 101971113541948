import store from '@/store';

// Swaps a style based on the current dashboard theme.
export default {
  styleToggle(lightThemeVariable, darkThemeVariable) {
    const skin = {
      value: store.state.appConfig.layout.skin,
    };
    if (skin.value !== 'dark') {
      return lightThemeVariable;
    }
    return darkThemeVariable;
  },
};
