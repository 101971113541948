<template>
  <DateRangePicker
    ref="picker"
    class="float-right bordered-time-range"
    :opens="opens"
    :locale-data="localeData"
    :minDate="min"
    :maxDate="max"
    :timePicker="true"
    :timePicker24Hour="false"
    :autoApply="false"
    :ranges="timeRanges"
    v-model="dateRange"
    @update="triggerRangeChange"
    :alwaysShowCalendars="false"
  >
  </DateRangePicker>
</template>

<script>
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

import dateCeil from '@/xvisor/utilities/dateCeil';

export default {
  components: { DateRangePicker },
  props: {
    showRanges: {
      type: Boolean,
      default: true,
    },
    ranges: {
      type: Object,
      default() {
        const now = dateCeil(new Date());
        return {
          'Last 6 hours': [moment(now).subtract(6, 'hours').toDate(), now],
          'Last 24 hours': [moment(now).subtract(1, 'days').toDate(), now],
          'Last week': [moment(now).subtract(1, 'week').toDate(), now],
          'Last month': [moment(now).subtract(1, 'month').toDate(), now],
        };
      },
    },
    minDate: {
      require: true,
    },
    maxDate: {
      require: true,
    },
    initRange: {
      type: Object,
      required: true,
    },
  },
  computed: {
    timeRanges() {
      if (this.showRanges) return this.ranges;
      return null;
    },
  },
  data() {
    return {
      min: null,
      max: null,
      dateRange: {
        startDate: dateCeil(this.initRange.start),
        endDate: dateCeil(this.initRange.end),
      },
      opens: 'left',
      localeData: {
        format: 'mmmm dd, yyyy h:MM TT',
        firstDay: 0,
      },
    };
  },
  mounted() {
    this.min = this.minDate;
    this.max = this.maxDate;
  },
  methods: {
    triggerRangeChange(value) {
      this.$emit('change-time-range', {
        start: dateCeil(value.startDate),
        end: dateCeil(value.endDate),
      });
    },
  },
};
</script>

<style lang="scss">
  .daterangepicker {
    font-family: "Montserrat", "Helvetica", "Arial", "sans-serif";
  }

  .calendars {
    display: -webkit-box !important;
    flex-wrap: nowrap !important;
  }

  .applyBtn {
    border: 0;
  }

  .cancelBtn {
    margin-left: -2px;
  }

  .ranges {
    font-weight: bold;
  }

  .daterangepicker select.hourselect,
  .daterangepicker select.minuteselect,
  .daterangepicker select.secondselect,
  .daterangepicker select.ampmselect {
    font-size: 14px !important;
    padding-left: 10px !important;
  }
  .vue-daterange-picker .reportrange-text {
    border-radius: 4px;
    border: none;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }

  // dark layout
  .dark-layout {

    // Popover styling
    .daterangepicker {
      background-color: #161d31;
    }
    .applyBtn {
      background-color: #08c;
    }
    .cancelBtn {
      color: #fff;
      background-color: #283046;
    }
    .ranges {
      color: #fff;
    }
    .daterangepicker select.hourselect,
    .daterangepicker select.minuteselect,
    .daterangepicker select.secondselect,
    .daterangepicker select.ampmselect {
      color: #fff;
      background: #161d31 !important;
      border: 1px solid #fff !important;
    }
    .daterangepicker .calendar-table {
      background-color: #161d31;
    }
    .daterangepicker td.off,
    .daterangepicker td.off.in-range,
    .daterangepicker td.off.start-date,
    .daterangepicker td.off.end-date {
      background-color: #161d31;
    }
    .daterangepicker td.in-range {
      background-color: #ebf4f8;
      border-color: transparent;
      color: #000;
      border-radius: 0;
    }
    .daterangepicker td.active,
    .daterangepicker td.active:hover {
      background-color: #357ebd;
      border-color: transparent;
      color: #fff;
    }
    .daterangepicker td.start-date {
      border-radius: 4px 0 0 4px;
    }
    .daterangepicker td.end-date {
      border-radius: 0 4px 4px 0;
    }
    .vue-daterange-picker .reportrange-text {
      background: #283046;
      border-radius: 4px;
      color: #d0d2d6;
    }
    .table-condensed th, .table-condensed td {
      background-color: #161d31;
      color: #fff;
    }
    .table-condensed td.disabled {
      background-color: #283046;
    }
    .bordered-time-range {
      border-style: solid;
      border-radius: 5px;
      border-width: 1px;
      border-color: #404656;
    box-shadow: 0 0 10px 0 rgba(black, 0.5) !important;
    }
  }
  // end of dark layout

  // bordered
  .bordered-layout .vue-daterange-picker .reportrange-text {
    border: 1px solid #ebe9f1;
    box-shadow: 0 0 10px 0 rgba(black, 0.5) !important;
  }
  //end of bordered

  select {
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .bordered-time-range {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #bfbfbf;
  }

  .daterangepicker .ranges li.active,
  .daterangepicker .ranges li:hover {
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    .vue-daterange-picker .reportrange-text {
      font-size: 10px;
    }

    .calendars {
      display: flex !important;
      flex-wrap: wrap !important;
    }

    .calendars-container {
      display: block;
    }
  }

  @media screen and (min-width: 550px) and (max-width: 1100px) {
    .vue-daterange-picker .reportrange-text {
      font-size: 12px;
    }

    .calendars {
      display: flex !important;
      flex-wrap: wrap !important;
    }

    .calendars-container {
      display: block;
    }
  }
</style>
