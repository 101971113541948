import moment from 'moment';

import TimeRangeSelector from '@/xvisor/components/TimeRangeSelector.vue';
import dateCeil from '@/xvisor/utilities/dateCeil';

export default {
  components: {
    TimeRangeSelector,
  },
  data() {
    const now = dateCeil(new Date());
    return {
      /** The time range for all components on the page. */
      timeRange: this.$store.state.app.timeRange,
      maxDate: now,
      minDate: moment(now).subtract(1, 'month').toDate(),
      shortTimeRange: { start: moment(now).subtract(5, 'minutes').toDate(), end: now },
    };
  },
  methods: {
    updateStateTimeRange(timeRange) {
      this.$store.commit('app/UPDATE_TIME_RANGE', timeRange);
      this.timeRange = timeRange;
    },
  },
};
