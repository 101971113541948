import colorPaletteShade from './colorPaletteShade';

export default {
  blue: '#7367F0',
  green: '#28C76F',
  red: '#EA5455',
  orange: '#FF9F43',
  black: '#1E1E1E',
  gray: colorPaletteShade.gray7,
  white: colorPaletteShade.white,
  yellow: colorPaletteShade.yellow5,
  teal: colorPaletteShade.teal5,
  indigo: colorPaletteShade.indigo5,
  purple: colorPaletteShade.purple5,
  pink: colorPaletteShade.pink5,
  fadedGray: '#777',
  lightGray: '#adb5bd',
  midGray: '#555',
  blueBackground: '#10163a',
  blueLightBackground: '#262c49',
  tooltipBackground: '#212744',
  lightThemeGreyBorder: '#ebe9f1',
  lightThemeText: '#6e6b7b',
  hoverColor: '#ddd',
  lackOfDataGray: '#F8F8F8',
  darkPink: '#FE00EB',
};
